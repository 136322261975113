/* eslint-disable quotes */
export default [
  // {
  //   header: "Invoice",
  //   resource: "Admin",
  //   action: "read",
  // },
  // {
  //   title: "Invoice",
  //   icon: "FileTextIcon",
  //   children: [
  //     {
  //       title: "List",
  //       route: "invoice-list",
  //       resource: "Admin",
  //       action: "read",
  //     },
  //     {
  //       title: "Preview",
  //       route: { name: "invoice-preview", params: { id: 4987 } },
  //       resource: "Admin",
  //       action: "read",
  //     },
  //     {
  //       title: "Edit",
  //       route: { name: "invoice-edit", params: { id: 4987 } },
  //       resource: "Admin",
  //       action: "read",
  //     },
  //     {
  //       title: "Add",
  //       route: { name: "invoice-add" },
  //       resource: "Admin",
  //       action: "read",
  //     },
  //   ],
  //   resource: "Admin",
  //   action: "read",
  // },
];
