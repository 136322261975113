<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]" @mouseenter="updateMouseHovered(true) & showDropdown()" @mouseleave="updateMouseHovered(false) & hideDropdown()">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo" >
                <b-img style="height: 35px;" src="@/assets/images/logo/logo2.png" alt="logo" />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{'d-block': shallShadowBottom}" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <!-- main menu footer @mouseleave="toggleExpand()"-->
    <div class="navbar-footer expanded" ref="navigationDropDown">
      <slot name="footer" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item mr-auto" id="user-dropdown">
            <b-nav-item-dropdown topright toggle-class="d-flex align-items-center dropdown-user-link"
              class="dropdown-user" style="margin-left: 1.5rem">
              <template #button-content>
                <b-avatar size="42" :src="userData.avatar" variant="light-primary" badge class="badge-minimal"
                  badge-variant="success">
                  <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
                </b-avatar>
              </template>

              <!-- <b-dropdown-item :to="{ name: 'pages-profile' }" link-class="d-flex align-items-center">
                  <feather-icon size="16" icon="UserIcon" class="mr-50" />
                  <span>Profile</span>
                </b-dropdown-item> -->

              <!-- <b-dropdown-divider /> -->
              <b-dropdown-item v-if="userData.role == 'admin' || userData.isSwitch =='true'"
                link-class="d-flex align-items-center" @click="userDashboard" v-model="count">
                <feather-icon size="16" icon="GridIcon" class="mr-50" />
                <span> {{dropdownName}}</span>
              </b-dropdown-item>

              <b-dropdown-item :to=" { name: 'pages-account-setting' }" link-class="d-flex align-items-center">
                <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
                <span>Settings</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item :to="{ name: 'pages-pricing' }" link-class="d-flex align-items-center">
                  <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
                  <span>Pricing</span>
                </b-dropdown-item> -->
              <!-- <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center">
                  <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
                  <span>FAQ</span>
                </b-dropdown-item> -->
              <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>Logout</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu footer-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BLink,
  BImg,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
// eslint-disable-next-line import/no-extraneous-dependencies
import { initialAbility } from "@/libs/acl/config";
// eslint-disable-next-line import/no-extraneous-dependencies
import useJwt from "@/auth/jwt/useJwt";
// eslint-disable-next-line import/no-extraneous-dependencies
import { avatarText } from "@core/utils/filter";
import useVerticalLayout from "../../useVerticalLayout";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      isAdminSwitch: localStorage.getItem("isAdminSwitch"),
      dropdownName: !localStorage.getItem("isAdminSwitch") ? 'User Dashboard' : 'Admin Dashboard',
      count: 0,
      avatarText
    };
  },

  mounted() {

    if (this.userData.role == 'admin') {
      this.dropdownName = 'User Dashboard'
    } else {
      this.dropdownName = 'Admin Dashboard'
    }


  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },

  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      //Remove isAdminSwitch
      localStorage.removeItem("isAdminSwitch");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    hideDropdown() {
      var menu = document.getElementById("user-dropdown");
      var dropdown = menu.children[0].children[1];
      if (dropdown != undefined) {
        if (dropdown.className == "dropdown-menu show") {
          dropdown.style.display = "none";
        }
      }
    },
    showDropdown() {
      var menu = document.getElementById("user-dropdown");
      var dropdown = menu.children[0].children[1];
      if (dropdown != undefined) {
        if (dropdown.className == "dropdown-menu show") {
          dropdown.style.display = "block";
        }
      }
    },


    userDashboard() {
      let userData = this.userData;
      let isAdminSwitch = localStorage.getItem("isAdminSwitch");
      let routerPush = '';;
      // let routerPush = '/';

      if (isAdminSwitch == "true") {
        localStorage.setItem("isAdminSwitch", 'false');
        this.dropdownName = 'User Dashboard';
        routerPush = '/admin/dashboard';
        userData.ability = [];
        userData.role = 'admin';
        userData.ability = [
          {
            action: 'manage',
            subject: 'Admin',
          },
          {
            action: 'manage',
            subject: 'Common',
          }
        ]
      } else {
        localStorage.setItem("isAdminSwitch", 'true');
        this.dropdownName = 'Admin Dashboard';
        routerPush = '/home/dashboard';
        userData.ability = [];
        userData.role = 'user';
        userData.ability = [
          {
            action: 'manage',
            subject: 'User',
          },
          {
            action: 'manage',
            subject: 'Common',
          },
        ];
      }


      userData.isSwitch = 'true';
      localStorage.setItem("userData", JSON.stringify(userData));

      this.$ability.update(userData.ability);

      this.$router.push(routerPush);

      this.$forceUpdate();
      this.count + 1;

    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

[dir] .nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
</style>
