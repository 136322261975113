/* eslint-disable semi */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [

  {
    title: "Plans",
    route: "plans",
    icon: "CreditCardIcon",
    resource: "Admin",
    action: "read",
  },
  // {
  //   title: "Add Plan",
  //   route: "plans-add",
  //   icon: "PlusIcon",
  //   resource: "Admin",
  //   action: "read",
  // },
];
