/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [
  // admin section
  {
    header: "OpenAI",
    resource: "Admin",
    action: "read",
  },

  {
    title: "Copy Writing",
    route: "CopyWriting",
    icon: "CloudRainIcon",
    resource: "Admin",
    action: "read",
  },
];
