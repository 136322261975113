/* eslint-disable semi */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [
  {
    header: "App Management",
    resource: "Admin",
    action: "read",
  },
  {
    title: "Users",
    icon: "UsersIcon",
    resource: "Admin",
    action: "read",
    children: [
      {
        title: "Users",
        route: "users",
        icon: "UsersIcon",
        resource: "Admin",
        action: "read",
      },
      {
        title: "Group Permission",
        route: "groupPermission",
        icon: "UserCheckIcon",
        resource: "Admin",
        action: "read",
      },
    ]
  },
  // {
  //   title: "Add User",
  //   route: "users-add",
  //   icon: "UserPlusIcon",
  //   resource: "Admin",
  //   action: "read",
  // },
];
