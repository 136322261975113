export default [
  {
    title: "Features",
    icon: "ClipboardIcon",
    resource: "Admin",
    action: "read",
    children: [
      {
        title: "Upcoming Features",
        route: "UpcomingFeatures",
        icon: "ThumbsUpIcon",
        resource: "Admin",
        action: "read",
      },
    ],
  },
];
