/* eslint-disable semi */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [

  {
    title: "Subscriptions",
    route: "subscriptions",
    icon: "FileTextIcon",
    resource: "Admin",
    action: "read",
  },
  // {
  //   title: "Add Subscription",
  //   route: "subscriptions-add",
  //   icon: "PlusIcon",
  //   resource: "Admin",
  //   action: "read",
  // },
];
