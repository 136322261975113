/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [
  // {
  //   header: "Settings",
  //   resource: "User",
  //   action: "read",
  // },
  // {
  //   title: "Settings",
  //   route: "pages-account-setting",
  //   icon: "SettingsIcon",
  // },
  // {
  //   title: "FAQ",
  //   route: "pages-faq",
  //   icon: "HelpCircleIcon",
  //   resource: "User",
  //   action: "read",
  // },
]
