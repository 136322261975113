export default [
  {
    // title: 'Home',
    // icon: 'HomeIcon',
    // route: 'home',
    // resource: 'Admin',
    // action: 'read',
  },
  {
    // title: 'User Home',
    // icon: 'HomeIcon',
    // route: 'user-home',
    // resource: 'User',
    // action: 'read',
  },
];
