/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
export default [
  // user section
  {
    header: "Listings",
    icon: "HomeIcon",
    resource: "User",
    action: "read",
  },
  {
    title: "General Listings",
    route: "Listings",
    icon: "MapIcon",
    resource: "User",
    action: "read",
  },
  {
    title: "Brisbane",
    route: "BCCListings",
    icon: "DatabaseIcon",
    resource: "User",
    action: "read",
  },
  {
    title: "Saved",
    route: "SavedListings",
    icon: "HeartIcon",
    resource: "User",
    action: "read",
  },
  {
    title: "Opportunity Feed",
    route: "OppertunityFeed",
    icon: "BookIcon",
    resource: "User",
    action: "read",
  },
  // {
  //   title: "Stock List",
  //   route: "Stocklist",
  //   icon: "FolderIcon",
  //   resource: "User",
  //   action: "read",
  // },
  {
    header: "Properties",
    icon: "HomeIcon",
    resource: "User",
    action: "read",
  },

  {
    title: "Area Search",
    route: "PropertySearch",
    icon: "BarChart2Icon",
    resource: "User",
    action: "read",
  },
  {
    title: "Property Lookup",
    route: "UserSearch&Save",
    icon: "BookmarkIcon",
    resource: "User",
    action: "read",
  },

  // admin section
  {
    header: "Property & Listings",
    resource: "Admin",
    action: "read",
  },
  {
    title: "Listings",
    icon: "MapPinIcon",
    resource: "Admin",
    action: "read",
    children: [
      // {
      //   title: "All",
      //   route: "AllListings",
      //   icon: "DatabaseIcon",
      //   resource: "Admin",
      //   action: "read",
      // },
      // {
      //   title: "QLD",
      //   route: "QLDListings",
      //   icon: "DatabaseIcon",
      //   resource: "Admin",
      //   action: "read",
      // },
      {
        title: "Manage",
        route: "ManageListings",
        icon: "EditIcon",
        resource: "Admin",
        action: "read",
      },
      {
        title: "Stock List",
        route: "StocklistAdmin",
        icon: "FolderIcon",
        resource: "Admin",
        action: "read",
      },
    ],
  },
  {
    title: "Property",
    icon: "HomeIcon",
    resource: "Admin",
    action: "read",
    children: [
      {
        title: "Manual Search & Add",
        route: "AdminSearch&Add",
        icon: "SearchIcon",
        resource: "Admin",
        action: "read",
      },
      // {
      //   title: "Reverse Search",
      //   route: "PropertySearch1",
      //   icon: "SearchIcon",
      //   resource: "Admin",
      //   action: "read",
      // },
    ],
  },

  // {
  //   title: 'Saved Properties',
  //   route: 'saved-properties',
  //   icon: 'SaveIcon',
  //   resource: "User",
  //   action: "read",
  // },

  //Team Member nav
  {
    title: "Listings",
    icon: "MapPinIcon",
    resource: "Team Member",
    action: "read",
    children: [
      {
        title: "Manage",
        route: "ManageListingsTeam",
        icon: "EditIcon",
        resource: "Team Member",
        action: "read",
      },
    ],
  },
];
