/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from "./dashboard";
import listings from "./listings";
import settings from "./settings";
import users from "./users";
import plans from "./plans";
import subscriptions from "./subscriptions";
import invoice from "./invoice";
import helper from "../../helper/helper";
import features from "./features";
import map from "./map";
import openai from "./openai";

// Array of sections
export default [...dashboard, ...listings, ...users, ...settings, ...plans, ...subscriptions, ...invoice, ...features, ...map, ...openai];
